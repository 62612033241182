<template>
	<!-- 免费领土地活动列表 -->
	<div class="adhibition-freeland-event el-content">
		<router-link to="/adhibition/freeLand/edit">
			<a-button type="primary">
				<i class="ri-add-line"></i>新增活动
			</a-button>
		</router-link>
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'土地名称',dataIndex:'land.land_name'},
			{title:'活动状态',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
			{title:'领取地块',dataIndex:'yiLingQu'},
			{title:'激活地块',dataIndex:'YiJiHuo'},
			{title:'空闲地块',dataIndex:'WeiChuZu'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #is_put_away="{record}">
				<a-tag :color="record.is_put_away == 1?'#00CC66':'#999'">{{record.is_put_away == 1 ?'上架中':'已下架'}}</a-tag>
			</template>
			<template #action="{record}">
				<a-space>
					<router-link :to="{path:'/adhibition/freeLand/edit',query:{id:record.id}}">
						<kd-button type="primary" 
							title="编辑" 
							icon="ri-edit-line" 
							v-has="{action:'wxapp_marketing_dis_rule_add'}">
						</kd-button>
					</router-link>
					<kd-button type="danger" 
						title="删除" 
						icon="ri-delete-bin-5-line" 
						v-has="{action:'wxapp_marketing_dis_rule_del'}"  
						@click="toDeleteEvent(record.id)">
					</kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getFreeLandEventList(info.page,e)}"
				@change="(e)=>{getFreeLandEventList(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import freeLandModel from '@/api/addons/freeLand'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
		})
		getFreeLandEventList(1,state.info.limit)
		function getFreeLandEventList(page,limit){
			freeLandModel.getFreeLand(page,limit,res=>state.info = {limit,...res})
		}

		const toDeleteEvent = id=>freeLandModel.deleteFreeLand(id,()=>{
			getFreeLandEventList(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getFreeLandEventList,
			toDeleteEvent
		}
	}
}
</script>

<style>
</style>
